/* index.css */

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #333;
}

.container {
  text-align: center;
  max-width: 350px;
  padding: 40px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
}

h1 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #000;
}

.upload-icon {
  font-size: 3em;
  color: #333;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
}

input[type="file"] {
  margin-bottom: 20px;
  font-size: 1em;
  color: #333;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

button {
  padding: 12px;
  background-color: #000;
  color: #fff;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #333;
}

.message {
  margin-top: 15px;
  color: #28a745;
}

.error {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

button.download {
  background-color: hsl(162, 85%, 33%); /* A brighter blue color */
  color: #fff; /* White text for contrast */
}

button.download:hover {
  background-color: hsl(133, 100%, 15%); /* A darker blue for hover effect */
}