/* General container styles */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
}

/* Title styling */
.login-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Input group styling */
.login-input-group {
    width: 100%;
    max-width: 400px;
    margin-bottom: 15px;
}

/* Input field styling */
.login-input {
    width: 100%;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
}

/* Button styling */
.login-button {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    background-color: black; /* Change to your preferred color */
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #333; /* Slightly lighter shade for hover */
}

/* Error message styling */
.login-error {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
    text-align: center;
}

/* Responsive design */
@media (max-width: 600px) {
    .login-container {
        padding: 10px;
    }
    .login-input-group {
        max-width: 300px;
    }
    .login-button {
        max-width: 300px;
    }
}
